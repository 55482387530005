import { StudentAnswersEnum } from './types';

export const pagesToQuestions = {
    about: [
        StudentAnswersEnum.first_name,
        StudentAnswersEnum.middle_name,
        StudentAnswersEnum.last_name,
        StudentAnswersEnum.preferred_name,
        StudentAnswersEnum.prev_lname,
        StudentAnswersEnum.gender,
        StudentAnswersEnum.gender_iden,
        StudentAnswersEnum.birth_date,
        StudentAnswersEnum.ssn,
        StudentAnswersEnum.phone_home,
        StudentAnswersEnum.phone_mobile,
        StudentAnswersEnum.optin_sms_ind,
        StudentAnswersEnum.address_line_1,
        StudentAnswersEnum.address_line_2,
        StudentAnswersEnum.city,
        StudentAnswersEnum.state,
        StudentAnswersEnum.zip,
        StudentAnswersEnum.country,
        StudentAnswersEnum.mailing_addr_same_as_perm_addr,
        StudentAnswersEnum.mailing_address_line_1,
        StudentAnswersEnum.mailing_address_line_2,
        StudentAnswersEnum.mailing_city,
        StudentAnswersEnum.mailing_state,
        StudentAnswersEnum.mailing_zip,
        StudentAnswersEnum.mailing_country,
        StudentAnswersEnum.mailing_addr_effective_date,
        StudentAnswersEnum.citizenship_type,
        StudentAnswersEnum.birth_country,
        StudentAnswersEnum.birth_state,
        StudentAnswersEnum.citizen_country,
        StudentAnswersEnum.alien_registration_number,
        StudentAnswersEnum.us_study_radio,
        StudentAnswersEnum.us_residency_radio,
        StudentAnswersEnum.citizenship_visa_status,
        StudentAnswersEnum.intended_visa_type,
        StudentAnswersEnum.current_visa_number,
        StudentAnswersEnum.ethnicity,
        StudentAnswersEnum.race_american_indian,
        StudentAnswersEnum.race_asian,
        StudentAnswersEnum.race_black,
        StudentAnswersEnum.race_native_hawaiian,
        StudentAnswersEnum.race_white,
        StudentAnswersEnum.tribe_radio,
        StudentAnswersEnum.tribe_affiliation,
        StudentAnswersEnum.tribe_affiliation_other,
        StudentAnswersEnum.military_status,
        StudentAnswersEnum.military_branch,
        StudentAnswersEnum.military_status_specified,
        StudentAnswersEnum.military_tuition_assistance,
        StudentAnswersEnum.military_self_edu_benefits,
        StudentAnswersEnum.military_self_veterans_benefits,
        StudentAnswersEnum.military_dependent,
        StudentAnswersEnum.military_spouse_status,
        StudentAnswersEnum.military_spouse_benefits,
        StudentAnswersEnum.law_enforcement,
    ],
    education: [
        StudentAnswersEnum.program_type,
        StudentAnswersEnum.applied_major,
        StudentAnswersEnum.app_concentration,
        StudentAnswersEnum.ctl_int_coll_univ,
        StudentAnswersEnum.ctl_int_elm_school,
        StudentAnswersEnum.ctl_int_ind_priv,
        StudentAnswersEnum.ctl_int_sec_school,
        StudentAnswersEnum.ctl_int_sec_comm_coll,
        StudentAnswersEnum.ce_int_geotechnical,
        StudentAnswersEnum.ce_int_structural,
        StudentAnswersEnum.ce_int_transportation,
        StudentAnswersEnum.ce_int_water_resources,
        StudentAnswersEnum.ga_int_european_studies,
        StudentAnswersEnum.ga_int_global_studies,
        StudentAnswersEnum.ga_int_la_studies,
        StudentAnswersEnum.ga_int_asian_studies,
        StudentAnswersEnum.ga_int_me_studies,
        StudentAnswersEnum.hw_int_water_man,
        StudentAnswersEnum.hw_int_water_qual,
        StudentAnswersEnum.hw_int_water_quan,
        StudentAnswersEnum.li_int_data,
        StudentAnswersEnum.li_int_info_tech,
        StudentAnswersEnum.li_int_pub,
        StudentAnswersEnum.li_int_school,
        StudentAnswersEnum.li_int_special,
        StudentAnswersEnum.li_int_arc_pres,
        StudentAnswersEnum.li_int_youth_serv,
        StudentAnswersEnum.se_int_appl_ba,
        StudentAnswersEnum.se_int_fund_se,
        StudentAnswersEnum.se_int_sec_trans,
        StudentAnswersEnum.cs_int,
        StudentAnswersEnum.geog_env_int,
        StudentAnswersEnum.ngem_int_engineering,
        StudentAnswersEnum.ngem_int_management,
        StudentAnswersEnum.entry_term,
        StudentAnswersEnum.parent_education,
        StudentAnswersEnum.parent_2_education,
        StudentAnswersEnum.prev_attend,
        StudentAnswersEnum.prev_attend_from_month,
        StudentAnswersEnum.prev_attend_to_month,
        StudentAnswersEnum.prev_attend_from_year,
        StudentAnswersEnum.prev_attend_to_year,
        StudentAnswersEnum.sooner_id,
        StudentAnswersEnum.college_degree,
        StudentAnswersEnum.college_gpa,
        StudentAnswersEnum.college_credits,
        StudentAnswersEnum.college_attend_from_month,
        StudentAnswersEnum.college_attend_to_month,
        StudentAnswersEnum.college_attend_from_year,
        StudentAnswersEnum.college_attend_to_year,
        StudentAnswersEnum.college_2_degree,
        StudentAnswersEnum.college_2_gpa,
        StudentAnswersEnum.college_2_credits,
        StudentAnswersEnum.college_2_attend_from_month,
        StudentAnswersEnum.college_2_attend_to_month,
        StudentAnswersEnum.college_2_attend_from_year,
        StudentAnswersEnum.college_2_attend_to_year,
        StudentAnswersEnum.college_3_degree,
        StudentAnswersEnum.college_3_gpa,
        StudentAnswersEnum.college_3_credits,
        StudentAnswersEnum.college_3_attend_from_month,
        StudentAnswersEnum.college_3_attend_to_month,
        StudentAnswersEnum.college_3_attend_from_year,
        StudentAnswersEnum.college_3_attend_to_year,
        StudentAnswersEnum.college_4_degree,
        StudentAnswersEnum.college_4_gpa,
        StudentAnswersEnum.college_4_credits,
        StudentAnswersEnum.college_4_attend_from_month,
        StudentAnswersEnum.college_4_attend_to_month,
        StudentAnswersEnum.college_4_attend_from_year,
        StudentAnswersEnum.college_4_attend_to_year,
        StudentAnswersEnum.college_5_degree,
        StudentAnswersEnum.college_5_gpa,
        StudentAnswersEnum.college_5_credits,
        StudentAnswersEnum.college_5_attend_from_month,
        StudentAnswersEnum.college_5_attend_to_month,
        StudentAnswersEnum.college_5_attend_from_year,
        StudentAnswersEnum.college_5_attend_to_year,
        StudentAnswersEnum.college_name,
        StudentAnswersEnum.college_address_line_1,
        StudentAnswersEnum.college_city,
        StudentAnswersEnum.college_state,
        StudentAnswersEnum.college_zip,
        StudentAnswersEnum.college_ceeb,
        StudentAnswersEnum.college_2_name,
        StudentAnswersEnum.college_2_address_line_1,
        StudentAnswersEnum.college_2_city,
        StudentAnswersEnum.college_2_state,
        StudentAnswersEnum.college_2_zip,
        StudentAnswersEnum.college_2_ceeb,
        StudentAnswersEnum.college_3_name,
        StudentAnswersEnum.college_3_address_line_1,
        StudentAnswersEnum.college_3_city,
        StudentAnswersEnum.college_3_state,
        StudentAnswersEnum.college_3_zip,
        StudentAnswersEnum.college_3_ceeb,
        StudentAnswersEnum.college_4_name,
        StudentAnswersEnum.college_4_address_line_1,
        StudentAnswersEnum.college_4_city,
        StudentAnswersEnum.college_4_state,
        StudentAnswersEnum.college_4_zip,
        StudentAnswersEnum.college_4_ceeb,
        StudentAnswersEnum.college_5_name,
        StudentAnswersEnum.college_5_address_line_1,
        StudentAnswersEnum.college_5_city,
        StudentAnswersEnum.college_5_state,
        StudentAnswersEnum.college_5_zip,
        StudentAnswersEnum.college_5_ceeb,
        StudentAnswersEnum.gmat_taken,
        StudentAnswersEnum.gmat_taken_month,
        StudentAnswersEnum.gmat_taken_year,
        StudentAnswersEnum.gmat_score_composite,
        StudentAnswersEnum.gmat_score_composite_percentile,
        StudentAnswersEnum.gmat_score_quant,
        StudentAnswersEnum.gmat_score_quant_percentile,
        StudentAnswersEnum.gmat_score_verbal,
        StudentAnswersEnum.gmat_score_verbal_percentile,
        StudentAnswersEnum.gmat_score_writing,
        StudentAnswersEnum.gmat_score_writing_percentile,
        StudentAnswersEnum.gmat_plan_month,
        StudentAnswersEnum.gmat_plan_year,
        StudentAnswersEnum.gmat_score_reasoning,
        StudentAnswersEnum.gmat_score_reasoning_percentile,
        StudentAnswersEnum.gre_taken,
        StudentAnswersEnum.gre_taken_month,
        StudentAnswersEnum.gre_plan_year,
        StudentAnswersEnum.gre_score_verbal,
        StudentAnswersEnum.gre_score_verbal_percentile,
        StudentAnswersEnum.gre_score_quant,
        StudentAnswersEnum.gre_score_quant_percentile,
        StudentAnswersEnum.gre_score_writing,
        StudentAnswersEnum.gre_score_writing_percentile,
        StudentAnswersEnum.gre_plan_month,
        StudentAnswersEnum.gre_plan_year,
        StudentAnswersEnum.toefl_taken,
        StudentAnswersEnum.toefl_taken_month,
        StudentAnswersEnum.toefl_taken_year,
        StudentAnswersEnum.toefl_score,
        StudentAnswersEnum.toefl_score_listening,
        StudentAnswersEnum.toefl_score_speaking,
        StudentAnswersEnum.toefl_score_reading,
        StudentAnswersEnum.toefl_score_writing,
        StudentAnswersEnum.toefl_plan_month,
        StudentAnswersEnum.toefl_plan_year,
        StudentAnswersEnum.ielts_taken,
        StudentAnswersEnum.ielts_taken_month,
        StudentAnswersEnum.ielts_taken_year,
        StudentAnswersEnum.ielts_score_overall,
        StudentAnswersEnum.ielts_score_listening,
        StudentAnswersEnum.ielts_score_reading,
        StudentAnswersEnum.ielts_score_writing,
        StudentAnswersEnum.ielts_score_speaking,
        StudentAnswersEnum.ielts_plan_month,
        StudentAnswersEnum.ielts_plan_year,
        StudentAnswersEnum.college_transcript,
        StudentAnswersEnum.identification,
    ],
    'final-steps': [
        StudentAnswersEnum.conviction,
        StudentAnswersEnum.conduct_question1_month,
        StudentAnswersEnum.conduct_question1_year,
        StudentAnswersEnum.conviction_comment,
        StudentAnswersEnum.suspended,
        StudentAnswersEnum.conduct_question2_month,
        StudentAnswersEnum.conduct_question2_year,
        StudentAnswersEnum.suspended_comment,
        StudentAnswersEnum.conduct_question3,
        StudentAnswersEnum.conduct_question3_month,
        StudentAnswersEnum.conduct_question3_year,
        StudentAnswersEnum.conduct_question3_explain,
        StudentAnswersEnum.conduct_question4,
        StudentAnswersEnum.conduct_question4_month,
        StudentAnswersEnum.conduct_question4_year,
        StudentAnswersEnum.conduct_question4_explain,
        StudentAnswersEnum.conduct_question5,
        StudentAnswersEnum.conduct_question5_month,
        StudentAnswersEnum.conduct_question5_year,
        StudentAnswersEnum.conduct_question5_explain,
        StudentAnswersEnum.legal_agree,
    ],
};
